<template>
  <div>
    <el-drawer
      :with-header="false"
      :size="1000"
      :visible.sync="drawer"
      :before-close="handleClose"
    >
    <div style="margin-top: 20px;"></div>
      <el-table :data="info" style="width: 95%;margin-left: 2%;">
        <el-table-column fixed prop="id" align="center" label="订单ID">
        </el-table-column>
        <el-table-column fixed prop="code" align="center" label="订单编号">
        </el-table-column>
        <el-table-column prop="billStatus" align="center" label="结算状态">
          <template slot-scope="scope">
             {{scope.row.billStatus==0?'未结算':'已结算'}}
          </template>
        </el-table-column>
        <el-table-column fixed prop="goodsName" align="center" label="商品名称">
        </el-table-column>
        <el-table-column fixed prop="discountPrice" align="center" label="抵扣金额">
        </el-table-column>
        <el-table-column fixed prop="goodsAmount" align="center" label="应收金额">
        </el-table-column>
        <el-table-column fixed prop="payAmount" align="center" label="实付金额">
        </el-table-column>
        <el-table-column fixed prop="quantity" align="center" label="订货数量">
        </el-table-column>
        <el-table-column fixed prop="scoreAmount" align="center" label="积分兑换">
        </el-table-column>
        <el-table-column fixed prop="sentQuantity" align="center" label="发货数量">
        </el-table-column>
        <el-table-column fixed prop="transactionId" align="center" label="微信支付交易流水号">
        </el-table-column>
      </el-table>
    </el-drawer>
  </div>
</template>

<script>
// import { userOrderApi, userDetailApi, userCouponApi, userBillApi,
//   modifyUserRefLog, userPointsApi, userSignLogApi, userHistoryApi, memberGrowthLog, userEditApi } from '@/api/user'
// import { verifyEmail } from '@/utils/toolsValidate';
export default {
  name: "FinanceBillDetails",
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Array,
      default: true,
    },
  },
  data() {
    return {
      moren: require("@/assets/images/f.png"),
    };
  },
  created() {
    console.log("123", this.info);
  },
  methods: {
    handleClose() {
      this.$emit("closeDrawer");
    },
  },
};
</script>

<style scoped lang="scss">
.head {
  padding: 20px 35px;
  .full {
    display: flex;
    align-items: center;
    .order_icon {
      width: 60px;
      height: 60px;
    }
    .iconfont {
      color: var(--prev-color-primary);
      &.sale-after {
        color: #90add5;
      }
    }
    .text {
      align-self: center;
      flex: 1;
      min-width: 0;
      padding-left: 12px;
      font-size: 13px;
      color: #606266;
      .title {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
        color: #282828;
      }
      .order-num {
        padding-top: 10px;
        white-space: nowrap;
      }
    }
  }
  .list {
    display: flex;
    margin-top: 20px;
    overflow: hidden;
    list-style: none;
    padding: 0;
    .item {
      flex: none;
      width: 33%;
      font-size: 14px;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.85);
      .title {
        margin-bottom: 12px;
        font-size: 13px;
        line-height: 13px;
        color: #666666;
      }
    }
  }
}
.tabNumWidth {
  max-height: 350px;
  overflow-y: auto;
  &:before {
    display: none;
  }
}
.el-tabs--border-card {
  box-shadow: none;
  border-bottom: none;
}
.section {
  .title {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 15px;
    color: #303133;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .item {
    flex: 0 0 calc(100% / 3);
    display: flex;
    margin-top: 16px;
    font-size: 13px;
    color: #606266;
    align-items: center;
    .item-title {
      width: 100px;
      text-align: right;
      margin: 10px;
      margin-left: 0;
    }
  }
  .item100 {
    padding-left: 0;
    flex: 0 0 calc(100% / 1);
    padding-left: 0 !important;
  }
  .contentPic {
    width: 500px;
    margin: 0 auto;
    max-height: 600px;
    overflow-y: auto;
    /deep/img {
      max-width: 100%;
    }
  }
  .value {
    // flex: 1;
    .value-item {
      &::after {
        content: "/";
        display: inline-block;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    .value-temp {
      &::after {
        content: "、";
        display: inline-block;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    image {
      display: inline-block;
      width: 40px;
      height: 40px;
      margin: 0 12px 12px 0;
      vertical-align: middle;
    }
  }
}
.tab {
  display: flex;
  align-items: center;
  .el-image {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }
}
/deep/.el-drawer__body {
  overflow: auto;
}

/deep/.ones th {
  background: #f0f5ff;
}
</style>
