import { get, put,Delete,post,get2 } from '../axios'

// 商品分类-新增
export const goodsCategoryAdd = params => post(`/goods/web/goodsCategory/add`,params);
// 商品分类-列表
export const goodsCategoryList = params => get(`/goods/web/goodsCategory/all/list`,params);
// 商品分类-删除
export const goodsCategoryDel = params => Delete(`/goods/web/goodsCategory/delete/${params}`);
// 商品分类-是否显示
export const goodsCategoryState = params => put(`/goods/web/goodsCategory/state`,params);
// 商品分类-编辑
export const goodsCategoryUpdate = params => put(`/goods/web/goodsCategory/update`,params);

//平台-财务管理-供应商-门店对账-批量结算
export const platformBillAdd = params => post(`/order/web/finance/platform/bill/add`,params);
//平台-财务管理-供应商-门店对账-对账单分页列表
export const platformBillPageList = params => get(`/order/web/finance/platform/bill/pageList`,params);
//平台-财务管理-供应商-门店对账-商品订单列表-统计
export const platformOrderCount = params => get(`/order/web/finance/platform/order/count`,params);
//平台-财务管理-门店对账-商品订单列表
export const platformStorePageList = params => get(`/order/web/finance/platform/store/pageList`,params);
//平台-财务管理-供应商对账-商品订单列表
export const platformSupplierPageList = params => get(`/order/web/finance/platform/supplier/pageList`,params);

//平台-财务管理-供应商-门店对账-批量审批-打款
export const financeBillBatch = params => post(`/order/web/finance/platform/bill/batch`,params);

//门店-财务管理-商品订单列表
export const storeOrderPageList = params => get(`/order/web/finance/store/order/pageList`,params);
//门店-财务管理-批量结算
export const storeBillAdd = params => post(`/order/web/finance/store/bill/add`,params);
//门店-财务管理-批量审批-打款
export const storeBillBatch = params => post(`/order/web/finance/store/bill/batch`,params);
//门店-财务管理-对账单分页列表导出
export const storeBillexportExcel = params => get(`/order/web/finance/store/bill/exportExcel`,params);
//门店-财务管理-对账单分页列表
export const storeBillPageList = params => get(`/order/web/finance/store/bill/pageList`,params);
//门店-财务管理-列表上方统计
export const storeOrderCount = params => get(`/order/web/finance/store/order/count/${params}`);

//平台-供应商商品对账单-查看
export const platformSupplierDetail = params => get(`/order/web/finance/platform/supplier/bill/detail/${params}`);

//平台-供应商商品对账单-查看
export const platformStoreDetail = params => get(`/order/web/finance/platform/store/bill/detail/${params}`);

//门店-门店商品对账单-查看
export const storeBillDetail = params => get(`/order/web/finance/store/bill/detail/${params}`);

//供应商-批量结算
export const supplierBillAdd = params => post(`/order/web/finance/supplier/bill/add`,params);

//供应商-批量审批-打款
export const supplierBillBatch = params => post(`/order/web/finance/supplier/bill/batch`,params);

//供应商-供应商商品对账单-查看
export const supplierBillDetail = params => get(`/order/web/finance/supplier/bill/detail/${params}`);

//供应商-对账单分页列表
export const supplierBillPageList = params => get(`/order/web/finance/supplier/bill/pageList`,params);

//供应商-列表上方统计
export const supplierOrderCount = params => get(`/order/web/finance/supplier/order/count/${params}`);

//供应商-对账单分页列表
export const supplierOrderDetail = params => get(`/order/web/finance/supplier/order/detail`,params);

//供应商-商品订单列表
export const supplierOrderPageList = params => get(`/order/web/finance/supplier/order/pageList`,params);


 